<template>
      <!-- <router-view /> -->
  <div>
      <div class="container-home survey-home">
      <HeaderTab ></HeaderTab>
      <top-banner></top-banner>
      <!-- 企业文化 -->
      <main class="main-auto" id="culture">
            <my-breadcrumb></my-breadcrumb>
            <section class="flex main" style="padding: 0 70px;">
                  <section>
                        <header-title :title="$t('home.culture')" height="160px"></header-title>
                        <div 
                        v-if="config.corporate_culture_info"
                        class="font_24 text_3" style="line-height:25px;letter-spacing:2px;margin-bottom:60px" 
                        v-html="config.corporate_culture_info.content.value">
                        </div>
                  </section>
            </section>
      </main>
      <!-- 留言板 -->
      <main class="main-auto" id="messageBoard">
            <section class="flex main" style="padding: 0 70px;">
                <section>
                    <header-title :title="$t('home.messageBoard')" height="160px"></header-title>
                    <article >
                        <messageBoards ></messageBoards>
                    </article>
                </section>
            </section>
      </main>
      <!-- 招商 -->
      <main class="main-auto" id="attractInvestment">
            <section class="flex main" style="padding: 0 70px;">
                <section>
                    <header-title :title="$t('home.investmentInfomation')" height="160px"></header-title>
                    <article >
                        <investmentInformation ></investmentInformation>
                    </article>
                </section>
            </section>
      </main>
    </div>
  </div>
</template>

<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex"
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from '@/components/TopBanner.vue';
import MyBreadcrumb from '@/components/Breadcrumb.vue';
import headerTitle from "@/components/header_title";
import messageBoards from '@/components/MessageBoard';
import investmentInformation from '@/components/InvestmentInformation';

export default {
  data () {
    return {
      id : "",
      htmlData : "",
    };
  },

  components: {
      HeaderTab,
      MyBreadcrumb,
      TopBanner,
      headerTitle,
      messageBoards,
      investmentInformation,
  },

  computed: {
         ...mapGetters(['tabList','secondMenu','config']),
  },

  async mounted() {

  },
  watch: {
        $route: {
            handler: function(val, oldVal){
            this.scrollTo(val)
            },
            // 深度观察监听
            deep: true
    }
  },
  methods: {
      // 锚点滚动函数
      scrollTo(val) {
            this.path_name = val.path.split('/')[val.path.split('/').length - 1]
            const el = document.querySelector(`#${this.path_name}`)
            if(!el) return
            el.scrollIntoView({behavior: "smooth"});
      },
  }
}

</script>
<style lang='scss' scoped>

.images {
      width: 100%;
      height: 720px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 6px;
      margin-top: 30px;
      margin-bottom: 60px;
}
section {
      margin-left: 0 !important;
}
</style>