<template>
  <div>
    <div class="container flex fw">
      <div
        @click="queryDetail(item, item.name)"
        class="list-item"
        v-for="(item, index) of list"
        :key="index"
      >
        <div style="overflow:hidden">
          <div
          :style="{ backgroundImage: 'url(' + item.domain_image + ')' }"
          class="images large"
          ></div>
        </div>
        <div class="list-item-content">
          <div class="list-item-tags only">
            {{ item.label }}
          </div>
          <div class="list-item-title only">
            {{ item.name }}
          </div>
          <div class="list-item-desc double">
            {{ item.synopsis }}
          </div>
        </div>
      </div>
    </div>
    <pagination-box
      :pagination="pagination"
      @pageChange="pageChange"
      v-if="isPagination"
    ></pagination-box>
  </div>
</template>
<script>
import paginationBox from "@/components/common/pagination.vue";
import { Indicator } from "mint-ui";
var util = require("@/utils/util");
export default {
  props: {
    isPagination: { type: Boolean, default: true },
  },
  data() {
    return {
      list: [],
      pagination: {}, //分页数据
      page: 1,
    };
  },
  components: {
    paginationBox,
  },
  async mounted() {
    Indicator.open(this.$t("tip.loading"));
    await this.commonGetList("os_activity");
    Indicator.close();
  },
  methods: {
    queryDetail(detail, title) {
      this.$emit("querydetail", detail, title);
    },
    commonGetList(table) {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("post", {
            url: "os/commonGetList",
            data: { table, page_size: 4, page: ts.page },
          })
          .then((res) => {
            if (res.code) {
              ts.list = res.data.list;
              ts.pagination = {
                page: res.data.current_page,
                pagecount: res.data.last_page,
                total: res.data.total,
                pagesize: res.data.per_page,
              };
            }
            resolve();
          })
          .finally(() => {
            resolve();
          });
      });
    },
    // 分页操作
    pageChange: async function (page) {
      this.page = page;
      Indicator.open(this.$t("tip.loading"));
      await this.commonGetList("os_activity");
      Indicator.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin-top: 31px;
  .list-item-content {
    padding: 17px 10px 25px;
  }
  .list-item:hover {
    box-shadow: 0px 0px 61px 1px rgba(0, 0, 0, 0.17);
  }
  .list-item {
    width: calc(24% - 16px);
    // height: 403px;
    margin-right: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    .images {
      width: 100%;
      height: 257px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
    }
    .list-item-title {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: bold;
      color: #000000;
      margin-top: 16px;
    }
    .list-item-open-time {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 8px;
    }
    .list-item-desc {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-top: 8px;
    }
    .list-item-label {
      padding: 4px 8px;
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--bg-color);
      border: 1px solid var(--bg-color);
      border-radius: 12px;
    }
    .query-detail {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--bg-color);
    }
    .list-item-tags {
      padding: 1px 2px;
      width: 68px;
      height: 22px;
      line-height: 22px;
      border-radius: 4px;
      font-size: 12px;
      color: #c8ab73;
      text-align: center;
      border: 1px solid #c8ab73;
    }
  }
}
.m-t-13 {
  margin-top: 13px;
}
.m-r-0 {
  margin-right: 0 !important;
}
</style>