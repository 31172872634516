<!-- 顶部banner -->
<template>
    <div class="article-list-box">
        <div class="article-list">
            <div @click="queryDetail(item.content,item.name)"  class="list-item flex jb" v-for="(item,index) of list" :key="index">
                <span class="title fwb">{{item.name}}</span>
            </div>
        </div>
        <!-- ============分页=============== -->
        <pagination-box :pagination="pagination" @pageChange="pageChange" ></pagination-box>
    </div>
</template>
<script>
import { Indicator } from "mint-ui";
var util = require("@/utils/util");
import paginationBox from '@/components/common/pagination.vue'; 
export default {
    name: "line",
    components: {
		paginationBox,
	},
    props: {

    },
    computed: {
    },
    data() {
        return {
            list : [],
            pagination: {}, //分页数据
            page:1
        }
    },
    async  mounted() {
        Indicator.open(this.$t('tip.loading'));
        await this.commonGetList('os_route');
        Indicator.close()
    },
    methods : {
        queryDetail(detail,title){
            this.$emit('querydetail',detail,title)
        },
        commonGetList(table){
            const ts = this;
            return new Promise( ( resolve ) => {
                util.requests('post',{
                    url: "os/commonGetList",
                    data : { table, page_size : 10 , page : ts.page}
                }).then( (res) => {
                    if ( res.code ) {
                        ts.list = res.data.list
                         ts.pagination = {
                            page: res.data.current_page,
                            pagecount: res.data.last_page,
                            total: res.data.total,
                            pagesize: res.data.per_page
                        }
                    }
                    resolve()
                } ).finally( () => {
                     resolve()
                } )
            } )
        },
                   // 分页操作
         pageChange: async function(page) {
            this.page = page
              Indicator.open(this.$t('tip.loading'));
                await this.commonGetList('os_route');
                Indicator.close()
            },
    }
}
</script>