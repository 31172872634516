<template>
    <div class="container-home survey-home">
        <HeaderTab></HeaderTab>
        <img class="survey-img" :src="info.image" alt="">
        <section class="detail-tabs flex ac">
            <div class="flex ac jc detail-pr" @click="$router.go(-1)">
                {{title}}
            </div>
        </section>
        <section class="detail-title">
            <section v-if="imgList.length > 0">
                <div class="swiper-container gallery-top">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item,index) in imgList">
                            <img :src="item" >
                        </div>
                    </div>
                    <div class="swiper-button-next swiper-button-white">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div class="swiper-button-prev swiper-button-white">
                        <i class="el-icon-arrow-left"></i>
                    </div>
                </div>
                <div class="swiper-container gallery-thumbs">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide swiper-bottom" v-for="(item,index) in imgList">
                            <img :src="item" >
                        </div>
                    </div>
                </div>
            </section>
            <section v-else  class="detaile-name flex ac jb">
                <div class="info-detail-name">{{info.title}}</div>
            </section>
            <section class="detail-contents" v-html="info.content || info.synopsis"></section>
            <section class="detail-table" v-if="ticketList.length > 0">
                <section class="td-title flex ac jc">
                    <div class="flex ac jc">房型</div>
                    <div class="flex ac jc">非周末门市价（不含门票）</div>
                    <div class="flex ac jc">周末门市价（不含门票）</div>
                    <div class="flex ac jc">数量</div>
                </section>
                <section class="td-cell flex ac jc" v-for="item in ticketList">
                    <div class="flex ac jc">{{item.room_title}}</div>
                    <div class="flex ac jc">{{item.weekdays_price}}</div>
                    <div class="flex ac jc">{{item.weekend_price}}</div>
                    <div class="flex ac jc">{{item.room_num}}</div>
                </section>
            </section>
        </section>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
export default {
    name: "detail",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            info:{},
            show:9,
            title:'首页 > 精彩活动 > 详情',
            url:"",
            imgList:[],
            ticketList:[],
        }
    },
    watch: {
        imgList() {
            setTimeout(() => {
                    this.galleryThumbsLunbo();
                    this.galleryTopLunbo();  
            }, 500);
        }
    },
    mounted() {
        if (this.$route.query.type == 'activity') {
            this.title = '首页 > 精彩活动 > 详情'
        } else if (this.$route.query.type == 'route') {
            this.title = '首页 > 推荐路线 > 详情'
        } else if (this.$route.query.type == 'travel') {
            this.title = '首页 > 旅游资讯 > 详情'
        } else if (this.$route.query.type == 'scenic') {
            this.title = '固城湾概况 > 重要景点 > 详情';
            this.show = 10;
        } else if (this.$route.query.type == 'hotel') {
            this.title = '固城湾概况 > 酒店住宿 > 详情'
            this.show = 10;
        } else if (this.$route.query.type == 'affairs') {
            this.title = '固城湾概况 > 会务活动 > 详情'
            this.show = 10;
        }else if (this.$route.query.type == 'ticket') {
            this.title = '在线预订 > 门票预订 > 详情'
            this.show = 11;
        }else if (this.$route.query.type == 'housing') {
            this.title = '在线预订 > 住房预订 > 详情'
            this.show = 11;
        }else if (this.$route.query.type == 'restaurant') {
            this.title = '在线预订 > 餐饮预订 > 详情'
            this.show = 11;
        }
        this.post()
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open(this.$t('tip.loading'));
            if (this.$route.query.type == 'affairs') {
                util.requests("post", {
                    url: "pc/getInfo",
                    data:{module:'yard_image',club_yard_id:ts.$route.query.id,club_id:ts.$route.query.club_id}
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.info = res.data.yard_info;
                        if (res.data.yardi_mage_list.length > 0) {
                            ts.info.image = res.data.yardi_mage_list[0],
                            ts.imgList =  res.data.yardi_mage_list
                        }
                    }
                });
            } else {
                util.requests("get", {
                    url: "pc/getInfo?module=" +ts.$route.query.type + '&id=' + ts.$route.query.id,
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        if (this.$route.query.type == 'hotel') {
                            ts.info = res.data.hotel_info;
                            if (res.data.all_image_list.length > 0) {
                                ts.info.image = res.data.all_image_list[0],
                                ts.imgList =  res.data.all_image_list
                            }
                            if (res.data.room_list.length > 0) {
                                ts.ticketList = res.data.room_list
                            }
                        } else {
                            ts.info = res.data
                        }
                    }
                });
            }

        },
        changeTab(e) {
            this.choice = e
        },
        showIndex(e) {
            this.List[e].show = !this.List[e].show
        },
        galleryThumbsLunbo() {
            this.galleryThumbs = new Swiper('.gallery-thumbs', {
                spaceBetween: 15,    //在slide之间设置距离（单位px）
                slidesPerView: 6,    //设置slider容器能够同时显示的slides数量
                loop: true,        //设置为true 则开启loop模式
                freeMode: true,    //默认为false，普通模式：slide滑动时只滑动一格
                loopedSlides: 5,    //一般设置大于可视slide个数2个即可
                watchSlidesVisibility: true,   //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
                watchSlidesProgress: true,   //开启这个参数来计算每个slide的progress(进度、进程)
            });
        },
        galleryTopLunbo() {
            this.galleryTop = new Swiper('.gallery-top', {
                spaceBetween: 10,
                loop: true,
                loopedSlides: 5,
                // 左右按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                thumbs: {        //thumbs组件专门用于制作带缩略图的swiper
                    swiper: this.galleryThumbs
                },
            });
        },
    }
}
</script>