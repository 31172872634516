<template>
    <div  class="flex dc ac jc">
        <div v-if="config && config.message_board_info && config.message_board_info.content" class="title" v-html="config.message_board_info.content.value"></div>
         <el-form ref="form" class="form" :model="form" label-width="0px">
            <el-form-item label="">
              <el-select 
              v-model="form.complaint_id" 
              :placeholder="$t('tip.type')">
                <el-option 
                v-for="(item,index) of options" 
                :key="index" 
                :label="item.label" 
                :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-input type="textarea" rows="4" :placeholder="$t('tip.content')" v-model="form.content"></el-input>
            </el-form-item>
              <el-form-item label="">
                <el-input :placeholder="$t('tip.contact')" v-model="form.tel"></el-input>
            </el-form-item>
            <div class="flex ac">
                <el-form-item  label="" >
                        <div class="flex ac">
                             <el-input class="captcha" v-model="form.captcha" name="captcha" type="text" :placeholder="$t('tip.code')" >
                              
                            </el-input>
                            <el-image class="code-image" slot="append" :src="captchaImg"></el-image>
                            <div @click="getCaptcha()" class="flex ac code-review">
                                <div class="review"></div>
                                <div class="review-text">{{$t('aboutWe.changeOne')}}</div>
                            </div>
                        </div>
                      
                </el-form-item>
              
            </div>
              
            <el-form-item>
                <div class="more m-t-10">
                    <span @click="onSubmit" class="btn more-btn">{{$t('aboutWe.submit')}}</span>
                </div>
            </el-form-item>
            </el-form>
    </div>
   

</template>

<script>
import { mapGetters } from "vuex"
var util = require("@/utils/util");
import { Indicator,Toast  } from "mint-ui";
  export default {
    data() {
      return {
        options: [],
        form: {
          complaint_id: '', // 类型
          content: '',// 内容
          tel: '',// 联系方式
          captcha : '',// 验证码
          captchaImg : "",
        },
      }
    },
    computed: {
      ...mapGetters(['config']),
    },
    created: function () {
      this.getCaptcha();
    },
    async mounted() {
      this.getOptions()
    },
    methods: {
      onSubmit() {
       Indicator.open(this.$t('tip.Submitting'));
        const ts = this;
        util.requests('post',{
          url : "os/bbsSubmit",
          data : this.form,
        }).then( (res) => {
          if ( res.code ) {
            ts.form =  {
              complaint_id: '', // 类型
              content: '',// 内容
              tel: '',// 联系方式
              captcha : '',// 验证码
              captchaImg : "",
            }
          } else {
            ts.getCaptcha()
          }
          Indicator.close()
         Toast(res.msg);
          ts.loading = false
        })
      },
      getCaptcha() {
        let random_num = Math.random();
        this.captchaImg = "/api/website/Captcha/index?r=" + random_num;
        this.$forceUpdate()
      },
      getOptions() {
        util.requests('post',{
          url : "os/getCommonOptions",
          data : { types: ["complaint"]},
        }).then( (res) => {
          this.options = res.data.complaint_options
        })
      }
    }
  }
</script>
<style scoped>
    .m-t-66 {
        margin-top: 66px;
    }
    .submit-button {
        width: 100%;
    }
    .el-form-item,.el-select {
        width: 479px;
    }
    .review {
        width: 18px;
        height: 18px;
        background-size: 100% 100%;
        background-image: url("../assets/review.png");
    }
    .review-text {
        font-size: 16px;
        color: var(--bg-color);
    }
    .captcha {
        flex: 1 !important;
    }
    .code-review {
        width: 80px;
    }
    .code-image {
        width: 120px;
        margin: 0 15px;
    }
    .title {
      max-width: 700px;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 600;
        color: #000000;
        margin-bottom: 34px;
    }
</style>