<template>
  <div class="flex ac jc">
    <el-form ref="form" class="form" :model="form" label-width="0px">
      <el-form-item label="">
        <el-input
          :placeholder="$t('tip.companyName')"
          v-model="form.company_name"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input
          :placeholder="$t('tip.companyContacts')"
          v-model="form.company_contacts"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input :placeholder="$t('tip.tel')" v-model="form.tel"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input
          :placeholder="$t('tip.industry')"
          v-model="form.industry"
        ></el-input>
      </el-form-item>
      <div class="flex ac">
        <el-form-item label="">
          <div class="flex ac">
            <el-input
              class="captcha"
              v-model="form.captcha"
              name="captcha"
              type="text"
              :placeholder="$t('tip.code')"
            >
            </el-input>
            <el-image class="code-image" slot="append" :src="captchaImg"></el-image>
            <div @click="getCaptcha()" class="flex ac code-review">
              <div class="review"></div>
              <div class="review-text">{{ $t("aboutWe.changeOne") }}</div>
            </div>
          </div>
        </el-form-item>
      </div>

      <el-form-item>
        <div class="more m-t-10">
          <span @click="onSubmit" class="btn more-btn">{{
            $t("aboutWe.submit")
          }}</span>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import baseconfig from "../../baseconfig";
var util = require("@/utils/util");
import { Indicator, Toast } from "mint-ui";
export default {
  data() {
    return {
      form: {
        company_name: "", // 企业名称
        company_contacts: "", // 企业联系人
        tel: "", // 企业联系电话
        industry: "", //所属行业
        captcha: "", // 验证码
        captchaImg: "",
      },
    };
  },
  created: function () {
    this.getCaptcha();
  },
  methods: {
    onSubmit() {
      Indicator.open(this.$t("tip.Submitting"));
      const ts = this;
      util
        .requests("post", {
          url: "os/joinInSubmit",
          data: this.form,
        })
        .then((res) => {
          if (res.code) {
            ts.form = {
              complaint_id: "", // 类型
              content: "", // 内容
              tel: "", // 联系方式
              captcha: "", // 验证码
              captchaImg: "",
            };
          } else {
            ts.getCaptcha();
          }
          Indicator.close();
          Toast(res.msg);
          ts.loading = false;
        });
    },
    getCaptcha() {
      let random_num = Math.random();
      this.captchaImg = `/api/website/Captcha/index?r=${random_num}`;
      // this.captchaImg =  "http://localhost:8085/api/website/Captcha/index?r=" + random_num;
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.m-t-66 {
  margin-top: 66px;
}
.submit-button {
  width: 100%;
}
.el-form-item {
  width: 479px;
}
.review {
  width: 18px;
  height: 18px;
  background-size: 100% 100%;
  background-image: url("../assets/review.png");
}
.review-text {
  font-size: 16px;
  color: var(--bg-color);
}
.captcha {
  flex: 1 !important;
}
.code-review {
  width: 80px;
}
.code-image {
  width: 120px;
  margin: 0 15px;
}
</style>