<template>
    <div class="container-home survey-home">
        <HeaderTab ></HeaderTab>
        <top-banner></top-banner>
        <main style="width:100%">
            <div class="main-auto">
                <my-breadcrumb></my-breadcrumb>
            </div>
            <section class="flex main">
                <section>
                    <div class="main-auto">
                        <header-title :title="$t('home.introduce')" height="160px"></header-title>
                        <div class="spots-content" v-html="config.synopsis_info.content.value"></div>
                    </div>
                    
                    <div class="spots-box">
                        <header-title :title="$t('home.scenic')" height="140px"></header-title>
                        <div class="spots-content main-auto" style="text-align: center" v-html="config.sign_scenic_info.content.value"></div>
                        <div class="main-auto flex jb" v-if="os_scenic.length !== 0">
                            <div class="spots-item m-t-1">
                                <div :style="{ backgroundImage : 'url(' + os_scenic[0].domain_image +')' }" class="images offsetR">

                                </div>
                                <div class="spots-title only" style="transform: translate(8px,-70%);">{{os_scenic[0].name}}</div>
                            </div>
                            <div class="spots-item m-t-2" style="transform: translateX(16px);">
                                <div :style="{ backgroundImage : 'url(' + os_scenic[1].domain_image +')' }" class="images offsetL">

                                </div>
                                <div class="spots-title only" style="position: absolute;right: 8px;top: -44px;">{{os_scenic[1].name}}</div>
                            </div>
                            <div class="spots-item m-t-1" style="transform: translateX(16px);">
                                <div :style="{ backgroundImage : 'url(' + os_scenic[2].domain_image +')' }" class="images offsetL">

                                </div>
                                <div class="spots-title only" style="position: absolute;right: 8px;bottom: -44px;">{{os_scenic[2].name}}</div>
                            </div>
                        </div>
                        <div class="more" 
                        style="margin-top:90px;" 
                        @click="goPage('/surveys/scenic')"
                        >{{$t("home.more")}}</div>
                    </div>
                    
                    <div class="main-auto">
                        <header-title :title="$t('home.hotel')" height="160px"></header-title>
                        <div class="container flex fw ">
                            <div :class="['list-item flex']" v-for="(item,index) of os_hotel" :key="index">
                                <div style="overflow:hidden">
                                    <div :style="{ backgroundImage : 'url(' + item.domain_image +')' }" class="list-item-images large"></div>
                                </div>
                                <div class="list-item-content">
                                    <div class="list-item-title only">
                                        {{ item.name }}
                                    </div>
                                    <div class="list-item-open-time">
                                        {{$t('hotel.tel')}}：{{ item.tel }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                        class="more"
                        @click="goPage('/surveys/hotel')"
                        >{{$t("home.more")}}</div>
                    </div>
                    
                </section>
            </section>
        </main>
        <!-- <footerTab></footerTab> -->
    </div>
</template>

<style scoped>
    section {
        margin-left: 0 !important;
    }
    .spots-item {
        width: 440px;
        height: 630px;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .offsetR, .offsetL {
        position: relative;
        height: 630px !important;
        border-radius: 4px !important;
    }
    .offsetR {
        transform: translate(16px, -16px);
    }
    .offsetL {
        transform: translate(-16px, -16px);
    }
    .m-t-1 {
        margin-top: 76px;
    }
    .m-t-2 {
        margin-top: 191px;
    }
    .spots-title {
        background: url("../../assets/body/column_title.png") no-repeat;
        width: 80px;
        height: 250px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        background-size: 100%;
        font-family: 'Source Han Serif SC-Heavy';
        writing-mode: vertical-lr;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .more {
        background: url("../../assets/body/more_.png") no-repeat;
        cursor: pointer;
        width: 184px;
        height: 48px;
        font-size: 12px;
        color: #fff;
        background-size: 100%;
        text-align: center;
        line-height: 43px;
        margin: 0 auto;
    }
    .article-title {
        border-bottom: none !important;
    }
    .spots-box {
        padding: 70px 0 60px;
        width: 100%;
        background-size: 100% 100% !important;
        background: url("../../assets/body/spots_bg.png") no-repeat 100% 100%;
        /* transform: translateX(-12.6%); */
    }
    .spots-content {
        color: #666666;
        font-size: 24px;
        font-family: Source Han Sans CN-Regular;
        margin-bottom: 50px;
    }
    .images {
        width: 100%;
        height: 720px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 10px;
    }
    .list-item {
        flex-direction: column;
        margin-right: 20px;
        width: calc(32% - 16px);
        margin-bottom: 50px;
        height: 464px;
        box-shadow: 0px 0px 10px 10px rgba(195, 195, 195, 0.1);
        border-radius: 10px 10px 10px 10px;
    }
    .list-item-images {
        width: 100%;
        height: 340px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        /* background-position: center; */
    }
    .list-item-content {
        padding: 30px;
    }
    .list-item-title {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: bold;
        color: #000000;
    }
    .list-item-open-time {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 8px;
    }
</style>

<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex"
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from '@/components/TopBanner.vue';
import MyBreadcrumb from '@/components/Breadcrumb.vue';
import ArticleLayout from '@/components/ArticleLayout.vue';
import footerTab from "@/components/Footer-bottom.vue";
import businessTimes from '@/components/BusinessTimes';
import lines from '@/components/Line'
import traffic from '@/components/Traffic';
import question from '@/components/Question';
import activity from '@/components/Activity';
import scenery from '@/components/Scenery';
import hotel  from '@/components/Hotel';
import sign from '@/components/Sign';
import detailInfo from '@/components/DetailInfo';
import headerTitle from "@/components/header_title";

import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        MyBreadcrumb,
        ArticleLayout,
        footerTab,
        TopBanner,
        businessTimes,
        lines,
        traffic,
        question,
        activity,
        scenery,
        hotel,
        detailInfo,
        sign,
        headerTitle
    },
    
    data() {
        return {
            htmlData : "",
            synopsis_info: {},
            os_scenic: [],
            os_hotel: [],
        }
    },
    watch: {
        affairs_content() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    computed: {
      ...mapGetters(['tabList','secondMenu','config','menuList']),
    },
   async  mounted() {
        this.id = this.$route.query.id;
        this.show =  this.$route.query.parent_id;
        this.commonGetList('os_scenic'); // 请求标志景点列表
        this.commonGetList('os_hotel'); // 请求精品酒店列表
    },
    methods: {
         commonGetList(table){
            const ps = table === 'os_scenic' ? 3 : 6
            return new Promise( ( resolve ) => {
                util.requests('post',{
                    url: "os/commonGetList",
                    data : { table, page_size : ps , page : this.page}
                }).then( (res) => {
                    if ( res.code ) {
                        this[table] = res.data.list
                    }
                    resolve()
                } ).finally( () => {
                     resolve()
                } )
            } )
        },
        goPage(page) {
            let e = "";
            let firstMenu = "";
            let secondMenu = "";
            this.$store.getters.menuList.forEach((item, index) => {
                if (item.children.length > 0) {
                item.children.forEach((childrenItem, childrenIndex) => {
                    if (childrenItem.page == page) {
                    e = childrenItem;
                    firstMenu = item.name;
                    secondMenu = childrenItem.name;
                    }
                });
                }
            });
            const nowLocation = firstMenu + " > " + secondMenu;
            this.$store.dispatch("setNowLocation", nowLocation);
            this.$store.dispatch("setSecondMenu", secondMenu);
            util.openPage({
                url: e.page,
                data: { id: e.id, parent_id: e.parent_id },
            });
        },
        getModel(e,url,secondMenu) {
            const ts = this
            let firstMenu = "";
            this.$store.getters.menuList.forEach( (item) => {
                if ( item.id == e.parent_id ) {
                    firstMenu = item.name
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            util.openPage({url,data : { id : e.id,parent_id :e.parent_id }})
         
        },
    }
}
</script>