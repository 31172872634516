<template>
    <div class="container-home survey-home">
        <HeaderTab @getPoint="getPoint"></HeaderTab>
        <top-banner></top-banner>
        <main class="main-auto">
            <my-breadcrumb></my-breadcrumb>
            <article-layout></article-layout>
        </main>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from '@/components/TopBanner.vue';
import MyBreadcrumb from '@/components/Breadcrumb.vue';
import ArticleLayout from '@/components/ArticleLayout.vue';
import footerTab from "@/components/Footer-bottom.vue";
import { Indicator } from "mint-ui";
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        MyBreadcrumb,
        ArticleLayout,
        footerTab,
        TopBanner
    },
    
    data() {
        return {
            info:[],
            content:'',
            scenic:[],
            hotel:[],
            affairs:[],
            show:10,
            banner:'',
            affairs_content:''
        }
    },
    watch: {
        affairs_content() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    mounted() {
        this.post();
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open(this.$t('tip.loading'));
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.banner = res.data[1].banner_list[0].image
                        ts.info =  res.data[1].children;
                    }
            });
            util.requests("post", {
                    url: "pc/surveyData",
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    ts.content = res.data.synopsis.content
                    ts.scenic = res.data.scenic;
                    ts.hotel = res.data.hotel;
                    ts.affairs = res.data.affairs; 
                    ts.affairs_content = res.data.affairs_content.content
                }
            });
        },
        Swipers(e) {
            const ts = this;
            if(e == 2) {
                var list = [];
                ts.hotel.forEach((item,index) => {
                    if(index > 0) {
                        list.push(item)
                    }
                });
                list[ts.hotel.length - 1] = ts.hotel[0]
                ts.hotel = list
            } else {
                var list = [];
                list.push(ts.hotel[ts.hotel.length - 1])
            
                ts.hotel.forEach((item,index) => {
                    if(index < ts.hotel.length -1) {
                        list.push(item)
                    }
                });
                ts.hotel = list
            }
        },
        showIndex(e) {
            this.List[e].show = !this.List[e].show
        },
        goInto(e,f) {
            if (f) {
                this.$router.push({path:'survey/detail',query: {type: e,id:f.id}})
            } else {
                this.$router.push({path:'survey/detail',query: {type: e}})
            }
        },
        goDetail(e,f) {
            this.$router.push({path:'/detail',query: {type: f, id:e.id}})
        },
        getPoint(e) {
            let anchorElement = document.getElementById(e);
            if(anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
}
</script>