<template>
  <div class="container-home survey-home">
    <top-banner></top-banner>
    <main>
      <div class="main-auto"> <my-breadcrumb></my-breadcrumb> </div>
      <header-title title="营业时间" height="260px" id="times"></header-title>
      <div class="section flex" style="padding: 0 46px; align-items: center;width:100%">
        <div class="flex main-auto">
          <div style="flex:3;" class="flex dc jc">
            <div 
            v-if="config.system_info"
            class="time_logo"
            :style="{
              background: `url(${config.system_info.logo.value[0]})`,
            }"
            ></div>
            <div class="logo-tip double" v-if="config.business_hours_info">{{config.business_hours_info.remark.value}}</div>
          </div>
          <div style="flex:7" class="flex dc">
            <div>
              <img src="@/assets/body/slide.png" class="slide" />
              <span class="time-tip" v-if="config.business_hours_info">{{config.business_hours_info.title1.value}}</span>
            </div>
            <div class="font_size" v-if="config.business_hours_info">{{config.business_hours_info.content1.value}}</div>
            <div>
                <img src="@/assets/body/slide.png" class="slide" />
                <span class="time-tip" v-if="config.business_hours_info">{{config.business_hours_info.title2.value}}</span>
              </div>
              <div class="font_size" v-if="config.business_hours_info">{{config.business_hours_info.content2.value}}</div>
          </div>
        </div>
      </div>
      <header-title title="园区地图" height="260px" id="map"></header-title>
      <div class="section">
        <div :style="{ backgroundImage : 'url(' + map_guide +')' }" class="images main-auto">
        </div>
      </div>
      <header-title title="交通指南" height="260px" id="traffic"></header-title>
      <div class="section_3 flex ac jc" style="width:100%">
        <div class="flex main-auto">
          <div class="flex dc" style="flex:3">
              <div 
              :class="['btn-default', currentIndex === index ? 'btn-active' : '']"
              v-for="(item, index) of trafficList"
              :key="index"
              @click="setIndex(index,item)">
                <img :src="item.icon" class="slide" style="margin-right:30px"/>
                <div>{{item.description}}</div>
              </div>
          </div>
          <div style="flex:7" class="border-strip" v-html="content"></div>
        </div>
        
      </div>
    </main>
    
    <header-title title="游客须知" height="260px" id="tip"></header-title>
    <div class="section_4" style="width:80%;height:100%;margin:0 auto;">
      <div v-for="(item,index) of noticeList" :key="index">
        <div class="notice-title">{{item.description}}</div>
        <div class="notice-content" v-html="item.value"></div>
      </div>
      
    </div>
    <header-title title="常见问题" height="260px" id="question"></header-title>
    <div class="section_4" style="width:80%;margin:0 auto;height:auto">
      <div v-for="(item,index) of questionList" :key="index"
      style="border-bottom: 1px solid #E1D2B6;padding-bottom:30px;margin-bottom: 24px;">
        <div class="question-title">{{item.name}}</div>
        <div class="question-content">{{item.answer}}</div>
      </div>
      <div 
      class="more" 
      style="margin:80px auto"
      @click="goPage('/service/question')"
      >查看更多</div>
    </div>

  </div>
</template>

<style scoped>
  .time_logo {
    /* width: 174px; */
    height: 78px;
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }
  .logo-tip {
    margin-top: 20px;
    font-size: 18px;
    color: #894824;
    font-weight: bold;
    width: 305px;
    font-family: 'Source Han Serif SC-Bold';
  }
  .time-tip {
    color: #894824;
    font-family: 'Source Han Serif SC-Bold';
    font-weight: bold;
    font-size: 32px;
    position: relative;
    top: -12px;
  }
  .font_size {
    color: #333;
    font-family: 'Source Han Serif SC-Bold';
    font-weight: bold;
    font-size: 32px;
    padding: 0 !important;
  }
  .btn-active {
    background: url("../../assets/body/btn-active.png") no-repeat !important;
    color: #FFF3ED !important;
    background-size: 100% 100% !important;
  }
  .btn-default {
    background: url("../../assets/body/btn-default.png") no-repeat;
    background-size: 100% 100%;
    color: #894824;
  }
  .btn-default,.btn-active {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    height: 66px;
    width: 280px;
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: bold;
  }
  .border-strip {
    background: url("../../assets/body/border-trans.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    top: -16px;
    color: #333333;
    font-size: 18px;
    font-family: 'Source Han Sans CN-Regular';
    padding: 36px;
  }
  .notice-title {
    font-weight: bold;
    font-size: 36px;
    font-family: 'Source Han Serif SC-Bold';
  }
  .notice-content {
    background: url("../../assets/body/border-trans.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    /* height: 201px; */
    position: relative;
    top: -16px;
    color: #333333;
    font-size: 18px;
    padding: 60px;
    margin: 32px 0 64px;
    font-family: 'Source Han Sans CN-Regular';
  }
  .question-title {
    color: #333333;
    font-size: 24px;
    font-family: 'Source Han Serif SC-Bold';
    font-weight: bold;
    margin-bottom: 21px;
  }
  .question-content {
    color: #666666;
    font-size: 18px;
  }
  .images {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 2px;
  }
  .more {
      background: url("../../assets/body/more_.png") no-repeat;
      cursor: pointer;
      width: 184px;
      height: 48px;
      font-size: 12px;
      color: #fff;
      background-size: 100%;
      text-align: center;
      line-height: 43px;
      margin: 0 auto;
  }
</style>

<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from "@/components/TopBanner.vue";
import MyBreadcrumb from "@/components/Breadcrumb.vue";
import ArticleLayout from "@/components/ArticleLayout.vue";
import footerTab from "@/components/Footer-bottom.vue";
import businessTimes from "@/components/BusinessTimes";
import headerTitle from "@/components/header_title";
import Name from "wowjs";
export default {
  name: "home",
  components: {
    HeaderTab,
    MyBreadcrumb,
    ArticleLayout,
    footerTab,
    TopBanner,
    businessTimes,
    headerTitle,
  },

  data() {
    return {
      path_name: "",
      map_guide: "",
      currentIndex : 0,
      btnList: {
        "aviation_content": require('@/assets/body/airplant.png'),
        "drive_content": require('@/assets/body/driveself.png'),
        "transit_content": require('@/assets/body/bus.png'),
        "train_content": require('@/assets/body/trans.png'),
      },
      trafficList: [],
      content: "",
      noticeList: [],
      questionList: [],
      visitors_notice_info: {},
    };
  },
  watch: {
    affairs_content() {
      this.$nextTick(() => {
        //页面渲染完，在执行
        setTimeout(() => {
          let wow = new Name.WOW({
            live: false,
          });
          wow.init();
        }, 100);
      });
    },
  },
  computed: {
    ...mapGetters(["tabList","menuList","secondMenu","config"]),
  },
  async mounted() {
    await this.commonGetList('os_faq'); // 常见问题列表
    this.map_guide = this.config.guide_map_info?.image.value[0]
    for (let i in this.config.traffic_info) {
      const o = this.config.traffic_info[i]
      o['icon'] = this.btnList[i]
      this.trafficList.push(o)
    }
    for (let i in this.config.visitors_notice_info) {
      const o = this.config.visitors_notice_info[i]
      this.noticeList.push(o)
    }
    this.content = this.trafficList[0]?.value
    this.id = this.$route.query.id;
    this.scrollTo(this.$route)
    this.show = this.$route.query.parent_id;

  },
  watch: {
    // 监听路由变化时执行锚点滚动
    $route: {
      handler: function(val, oldVal){
        this.scrollTo(val)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    // 锚点滚动函数
    scrollTo(val) {
      this.path_name = val.path.split('/')[val.path.split('/').length - 1]
      const el = document.querySelector(`#${this.path_name}`)
      if(!el) return
      el.scrollIntoView({behavior: "smooth"});
    },
    setIndex(i,item) {
      this.currentIndex = i
      this.content = item.value
    },
    goPage(page) {
      let e = "";
      let firstMenu = "";
      let secondMenu = "";
      this.$store.getters.menuList.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childrenItem, childrenIndex) => {
            if (childrenItem.page == page) {
              e = childrenItem;
              firstMenu = item.name;
              secondMenu = childrenItem.name;
            }
          });
        }
      });
      const nowLocation = firstMenu + " > " + secondMenu;
      console.log(firstMenu,secondMenu);
      this.$store.dispatch("setNowLocation", nowLocation);
      this.$store.dispatch("setSecondMenu", secondMenu);

      util.openPage({
          url: e.page,
          data: { id: e.id, parent_id: e.parent_id },
      });
    },
    commonGetList(table){
        const ts = this;
        return new Promise( ( resolve ) => {
            util.requests('post',{
                url: "os/commonGetList",
                data : { table, page_size : 5 , page : ts.page}
            }).then( (res) => {
                if ( res.code ) {
                    ts.questionList = res.data.list
                      ts.pagination = {
                        page: res.data.current_page,
                        pagecount: res.data.last_page,
                        total: res.data.total,
                        pagesize: res.data.per_page
                    }
                }
                resolve()
            } ).finally( () => {
                  resolve()
            } )
        } )
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>