<template>
    <div v-if="config" class="container">
        <div v-if="config.traffic_info.aviation_content.value" class="list-item-box flex ac">
            <!-- <div  class="aircraft-icons icons ">

            </div> -->
            <div class="list-item ">
                 <div class="list-item-title"> {{$t('traffic.aviation')}} </div>
                 <div class="list-otem-detail" v-html="config.traffic_info.aviation_content.value"> </div>
            </div>
            <div class="line-icon"></div>
        </div>
         <div v-if="config.traffic_info.drive_content.value" class="list-item-box flex ac">
            <!-- <div  class="driving-icons icons">

            </div> -->
            <div class="list-item ">
                 <div class="list-item-title">{{$t('traffic.selfDriving')}}</div>
                 <div class="list-otem-detail" v-html="config.traffic_info.drive_content.value"></div>
            </div>
            <div class="line-icon"></div>
        </div>

         <div v-if="config.traffic_info.transit_content.value" class="list-item-box flex ac">
            <!-- <div  class="transit-icons icons">

            </div> -->
            <div class="list-item ">
                 <div class="list-item-title"> {{$t('traffic.transit')}} </div>
                 <div class="list-otem-detail" v-html="config.traffic_info.transit_content.value"></div>
            </div>
            <div class="line-icon"></div>
        </div>

         <div v-if="config.traffic_info.train_content.value"  class="list-item-box flex ac">
            <!-- <div  class="train-icons icons">

            </div> -->
            <div class="list-item ">
                 <div class="list-item-title">  {{$t('traffic.train')}} </div>
                 <div class="list-otem-detail" v-html="config.traffic_info.train_content.value"></div>
            </div>
            <div class="line-icon"></div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
    data(){
        return {
            traffic_info : ""
        }
    },
       async  mounted() {
       
        this.id = this.$route.query.id;
        this.show =  this.$route.query.parent_id;
    },
    computed: {
      ...mapGetters(['config']),
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
    .container >>> img {
            max-width: 1000px !important;
        }
    .container {
        margin-top: 52px;
        .list-item-box {
            border: 1px solid #E3E3E3;
            padding: 15px;
            margin-bottom: 24px;
            position: relative;
            .icons {
                width: 60px;
                height: 60px;
                background-size: 100% 100%;
                margin-right: 30px;
            
            }
            .aircraft-icons {
                background-image: url("../assets/aircraft.png");
            }
            .driving-icons {
                background-image: url("../assets/driving.png");
            }
            .transit-icons {
                background-image: url("../assets/transit.png");
            }
            .train-icons {
                background-image: url("../assets/train.png");
            }
            .list-item {
                flex: 1;
                .list-item-title {
                    font-size: 18px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #000000;
                    margin-bottom: 8px;
                }
                .list-otem-detail {
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
            .line-icon {
                position: absolute;
                left: 0;
                width: 4px;
                height: 48px;
                background-color:  var(--bg-color);
            }
        }
    }
</style>