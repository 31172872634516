<template>
  <div class="container-home survey-home">
    <top-banner></top-banner>
    <main class="main-auto wd_80 mg_auto">
      <my-breadcrumb></my-breadcrumb>
      <div class="section_box mg_auto">
        <div class="special_serve" v-for="(item,index) in speciallist" :key="index">
          <div
            v-if="index % 2 != 1"
            class="flex ac jb word_style"
            style="height: 100%"
          >
            <div class="content flex dc as">
              <div
                class="font_28 bold flex jc ac linear_graddit"
                style="color: #894824"
              >
                <img src="@/assets/body/slide.png" class="slide" />{{item.name}}
              </div>
              <div class="font_24 text_3h" style="width: 100%;" v-html="item.synopsis"></div>
              <div class="font_18 more_bg flex ac jc" @click="details(item)">
                查看详情
              </div>
            </div>
            <div
              :style="{ backgroundImage: 'url(' + item.domain_list_image + ')' }"
              class="images"
            ></div>
          </div>
          <div v-else class="flex ac jb word_style" style="height: 100%">
            <div
              :style="{ backgroundImage: 'url(' + item.domain_list_image + ')' }"
              class="images"
            ></div>
            <div class="content flex dc ae" style="text-align: right">
              <div
                class="font_28 bold flex jc ac linear_graddit_right"
                style="color: #894824"
              >
                <img src="@/assets/body/slide.png" class="slide" />{{item.name}}
              </div>
              <div class="font_24 text_3h" style="width: 100%;" v-html="item.synopsis"></div>
              <div class="font_18 more_bg flex ac jc" @click="details(item)">
                查看详情
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from "@/components/TopBanner.vue";
import MyBreadcrumb from "@/components/Breadcrumb.vue";
import ArticleLayout from "@/components/ArticleLayout.vue";
import footerTab from "@/components/Footer-bottom.vue";
import businessTimes from "@/components/BusinessTimes";
import headerTitle from "@/components/header_title";
import articleList from "@/components/ArticleList.vue";
import Name from "wowjs";
export default {
  name: "home",
  components: {
    articleList,
    HeaderTab,
    MyBreadcrumb,
    ArticleLayout,
    footerTab,
    TopBanner,
    businessTimes,
    headerTitle,
  },

  data() {
    return {
      speciallist: [],
    };
  },
  watch: {
    affairs_content() {
      this.$nextTick(() => {
        //页面渲染完，在执行
        setTimeout(() => {
          let wow = new Name.WOW({
            live: false,
          });
          wow.init();
        }, 100);
      });
    },
  },
  computed: {
    ...mapGetters(["tabList", "secondMenu","config","nowLocation"]),
  },
  async mounted() {
    this.id = this.$route.query.id;
    this.show = this.$route.query.parent_id;
    await this.commonGetList("os_especially"); // 特色服务列表
  },
  methods: {
    details(value) {
      console.log(value);
      let firstMenu = "";
      let secondMenu = "";
      this.$store.getters.menuList.forEach((item, index) => {
          if (item.children.length > 0) {
            item.children.forEach((childrenItem, childrenIndex) => {
                if (childrenItem.name == value.name) {
                  firstMenu = item.name;
                  secondMenu = childrenItem.name;
                }
            });
          }
      });
      // let nLoc = this.nowLocation
      let nLoc = '首页 > ' + firstMenu + ' > ' + value.name
      if(nLoc.indexOf('详情') < 0) {
        this.$store.dispatch("setNowLocation", nLoc + " > " + '详情');
      } else {
        this.$store.dispatch("setNowLocation", nLoc);
      }
      console.log(firstMenu);
      this.$store.dispatch("setSecondMenu", value.name);
      this.$router.push({
        path: "/special/detail",
        query: { 
          id: value.id,
          title: value.name,
          htmlData: value.content,
          img: value.domain_index_image,
          firstMenu: "特色服务",
        },
      });
    },
    commonGetList(type) {
      const ts = this;
      return new Promise((resolve) => {
        util
          .requests("post", {
            url: "os/commonGetList",
            data: { table: type },
          })
          .then((res) => {
            if (res.code) {
              this.speciallist = res.data.list
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
  .images {
    height: 253px;
    width: 468px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
  }
</style>