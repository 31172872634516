<template>
    <div class="container-home survey-home">
        <HeaderTab ></HeaderTab>
        <top-banner></top-banner>
        <main class="main-auto">
            <my-breadcrumb></my-breadcrumb>
            <section class="flex main">
                <section>
                    <header-title :title="$t('home.culture')" height="160px"></header-title>
                    <div 
                    v-if="config.corporate_culture_info"
                    class="font_24 text_3" style="line-height:25px;letter-spacing:2px;margin-bottom:60px" 
                    v-html="config.corporate_culture_info.content.value">
                    </div>
                </section>
            </section>
        </main>
    </div>
</template>

<style scoped>
    .images {
        width: 100%;
        height: 720px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 6px;
        margin-top: 30px;
        margin-bottom: 60px;
    }
    section {
        margin-left: 0 !important;
    }
</style>

<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex"
import HeaderTab from "@/components/Header-tab.vue";
import TopBanner from '@/components/TopBanner.vue';
import MyBreadcrumb from '@/components/Breadcrumb.vue';
import ArticleLayout from '@/components/ArticleLayout.vue';
import footerTab from "@/components/Footer-bottom.vue";
import detailInfo from '@/components/DetailInfo';
import headerTitle from "@/components/header_title";
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        MyBreadcrumb,
        ArticleLayout,
        footerTab,
        TopBanner,
        detailInfo,
        headerTitle,
    },
    
    data() {
        return {
            id : "",
            htmlData : "",
            image: 'https://guchengwan.oss-cn-beijing.aliyuncs.com/image/6mrst7b6af00000.jpg',
        }
    },
    watch: {
        affairs_content() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    computed: {
      ...mapGetters(['tabList','secondMenu','config']),
    },
   async  mounted() {
        this.id = this.$route.query.id;
        this.show =  this.$route.query.parent_id;
    },
    methods: {
        getModel(e,url,secondMenu) {
            const ts = this
            let firstMenu = "";
            this.$store.getters.menuList.forEach( (item) => {
                if ( item.id == e.parent_id ) {
                    firstMenu = item.name
                }
            } )
            const nowLocation = firstMenu + " > " +  secondMenu
            this.$store.dispatch('setNowLocation',nowLocation)
            this.$store.dispatch('setSecondMenu',secondMenu)
            util.openPage({url,data : { id : e.id,parent_id :e.parent_id }})
         
        },
 
    }
}
</script>