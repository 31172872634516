<!-- 顶部banner -->
<template>
    <div class="hotel-info">
        <div class="title">
            <div class="flex jc ac">
                <img src="@/assets/body/slide.png" alt="" class="slide" />
                <div class="font_size">{{ title }}</div>
                <img src="@/assets/body/slide.png" alt="" class="slide rotate_180" />
            </div>
        </div>
    </div>
</template>
<script>
var util = require("@/utils/util");
import { mapGetters } from "vuex";
import Swiper from "swiper";
import carouselItem from "@/components/carousel/src/item.vue";
export default {
    name: "hotel-info",
    components: {
        carouselItem
    },
    props: {
        htmlData : String,
        title :String
    },
    watch: {
        bennrtList() {
            const ts = this;
            ts.$nextTick(() => {
                ts.initSwiper();
            });
        },
    },
    computed: {
        ...mapGetters(["bennrtList"]),
    },
    data() {
        return {
            bannerImg: [],
            infoSwiper: null,
            articleIdx: 0,
            show: "",
            info: [],
            activity: [],
            route: [],
            news: [],
            choice_zixun: {},
            banner_info: "",
            LangeList: [],
            infoList: [],
            commonList: [],
            noticList: [],
            activityList: [],
            scenicList: [],
            showTopButton: false,
            bannerSwiper: "",
            bannerIndex: "",
        }
    },
    methods: {
        initSwiper() {
            const ts = this;
            this.infoSwiper = new Swiper(".info-swiper", {
                autoplay: false,
                centeredSlides: true,
            });
            if (this.bennrtList.length > 1) {
                this.bannerSwiper = new Swiper(".banner-swiper", {
                autoplay: true,
                loop: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                on: {
                    slideChange: function () {
                    ts.bannerIndex = this.realIndex;
                    ts.$forceUpdate();
                    },
                },
                });
            }

            new Swiper("#certify .swiper-container", {
                watchSlidesProgress: true,
                slidesPerView: "auto",
                centeredSlides: true,
                loop: true,
                loopedSlides: 5,
                autoplay: true,
                navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                },
                pagination: {
                el: ".swiper-pagination",
                //clickable :true,
                },
                on: {
                progress: function (progress) {
                    for (let i = 0; i < this.slides.length; i++) {
                    var slide = this.slides.eq(i);
                    var slideProgress = this.slides[i].progress;
                    let modify = 1;
                    if (Math.abs(slideProgress) > 1) {
                        modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                    }
                    let translate = slideProgress * modify * 210 + "px";
                    let scale = 1 - Math.abs(slideProgress) / 5;
                    let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                    slide.transform(
                        "translateX(" + translate + ") scale(" + scale + ")"
                    );
                    slide.css("zIndex", zIndex);
                    slide.css("opacity", 1);
                    if (Math.abs(slideProgress) > 3) {
                        slide.css("opacity", 0);
                    }
                    }
                },
                setTransition: function (transition) {
                    for (var i = 0; i < this.slides.length; i++) {
                    var slide = this.slides.eq(i);
                    slide.transition(transition);
                    }
                },
                },
            });
        },
    }
}
</script>
<style scoped>
    section {
        margin: 0;
    }
    .hotel-info >>> img {
        max-width: 1000px !important;
    }
    .title {
        font-size: 28px;
        font-weight: bold;
        color: #894824;
        margin-top: 37px;
    }
    .content-f {
        color: #333333;
        font-size: 24px;
        margin-top: 37px;
    }
</style>